import React from "react";

const MEDSCANSupport = () => {
    return (
        <div style={styles.container}>
            <section id="support" style={styles.section}>
                <h1 style={styles.heading}>MEDSCAN Support</h1>
                <p style={styles.text}>
                    Welcome to MEDSCAN Support. Here, we will place information for support purposes.
                    This content section will be expected to grow as we document and solve
                    issues with the app.
                </p>
                <p style={styles.text}>
                    Any issues or concerns with installation or usage of the application should 
                    be directed to Thomas Scherr (contact information provided with the app distribution).
                </p>
            </section>
        </div>
    );
};

const styles = {
    container: {
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        color: "#333",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        maxWidth: "800px",
        margin: "20px auto",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    section: {
        marginBottom: "40px",
    },
    heading: {
        fontSize: "2em",
        color: "#d32f2f",
        marginBottom: "20px",
    },
    text: {
        marginBottom: "15px",
    },
};

export default MEDSCANSupport;
