import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Research from "./pages/Research";
import TopNav from "./components/shared/TopNav";
import Footer from "./components/shared/Footer";
import Publications from "./pages/Publications";
import Software from "./pages/Software";
import SolarU from "./pages/SolarU";
import MEDSCANSupport from "./pages/MEDSCANSupport";
import MEDSCANLabApp from "./pages/MEDSCANLabApp";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <TopNav />
        <main className="flex-grow">
          <Routes>
            {/* Define your routes here */}
            <Route path="/" caseSensitive={false} element={<Home />} />
            <Route path="/home" caseSensitive={false} element={<Home />} />
            <Route path="/research" caseSensitive={false} element={<Research />} />
            <Route path="/publications" caseSensitive={false} element={<Publications />} />
            <Route path="/software" caseSensitive={false} element={<Software />} />
            <Route path="/solaru" caseSensitive={false} element={<SolarU />} />
            <Route path="/medscansupport" caseSensitive={false} element={<MEDSCANSupport />} />
            <Route path="/medscanlabapp" caseSensitive={false} element={<MEDSCANLabApp />} />
            {/* You can add more routes here for other pages */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
