import React from "react";

const MEDSCANLabApp = () => {
    return (
        <div style={styles.container}>
            <section id="support" style={styles.section}>
                <h1 style={styles.heading}>MEDSCAN Lab App</h1>
                <p style={styles.text}>
                    Welcome to the MEDSCAN Lab App.
                </p>
                <p style={styles.text}>
                    This app is designed to assist in the analysis of point-of-care diagnostic tests.
                    Please contact Thomas Scherr for more information.
                </p>
            </section>
        </div>
    );
};

const styles = {
    container: {
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        color: "#333",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        maxWidth: "800px",
        margin: "20px auto",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    section: {
        marginBottom: "40px",
    },
    heading: {
        fontSize: "2em",
        color: "#d32f2f",
        marginBottom: "20px",
    },
    text: {
        marginBottom: "15px",
    },
};

export default MEDSCANLabApp;
